import { render, staticRenderFns } from "./ZohoCallback.vue?vue&type=template&id=dc2b9d72&scoped=true&"
import script from "./ZohoCallback.vue?vue&type=script&lang=ts&"
export * from "./ZohoCallback.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc2b9d72",
  null
  
)

export default component.exports