var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
let ZohoCallback = class ZohoCallback extends Vue {
    constructor() {
        super(...arguments);
        this.code = '';
    }
    mounted() {
        //@ts-ignore
        this.code = decodeURI(this.$route.query.code);
        this.getTokenZoho();
    }
    async getTokenZoho() {
        //@ts-ignore
        await this.actions.getTokenZoho({
            client_id: this.zohoClientId,
            client_secret: this.zohoClientSecret,
            code: this.code,
            redirect_uri: this.zohoRedirectUrl,
        });
    }
    get zohoClientSecret() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? '02909ee800abce8bf753f1019af4318789eea5e7ed'
            : hostname.match('test')
                ? 'ca6c399dfdfd2578964bcbce49ec0a61ba7fecf3a3'
                : hostname.match('app')
                    ? '036e48f810698a2646f2e05d861dc564342e6d09ce'
                    : hostname.match('staging')
                        ? 'ca6c399dfdfd2578964bcbce49ec0a61ba7fecf3a3'
                        : '7f5c879002ec61a49ad647430551c3dfd8cb2c8b50';
    }
    get zohoClientId() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? '1000.JORTZZ0FYUK4MYJWH213HYZQZBU0QT'
            : hostname.match('test')
                ? '1000.UDTISKVCTIPDIIOVO1PICD5KMPTUUE'
                : hostname.match('app')
                    ? '1000.ST29HORWYJ64BLYOE8H8OP7F7RHUEU'
                    : hostname.match('staging')
                        ? '1000.UDTISKVCTIPDIIOVO1PICD5KMPTUUE'
                        : '1000.N6HPK79ZLI6SK8VG26EXVMPSAVUYLI';
    }
    get zohoRedirectUrl() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? 'https://dev.speedio.com.br/crm/zoho/callback'
            : hostname.match('test')
                ? 'https://staging.speedio.com.br/crm/zoho/callback'
                : hostname.match('app')
                    ? 'https://app.speedio.com.br/crm/zoho/callback'
                    : hostname.match('staging')
                        ? 'https://staging.speedio.com.br/crm/zoho/callback'
                        : 'http://localhost:8080/crm/zoho/callback';
    }
};
ZohoCallback = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'ZohoCallback',
    }))
], ZohoCallback);
export default ZohoCallback;
